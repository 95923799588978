import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

export const TermsContainer = styled.div`
    width:75%;
    max-width: 1200px;
    margin:60px auto;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,.12);
    background-color: #fff;
    padding: 60px;
    @media ${deviceLessThan.tablet}{
        padding: 15px 12px;
        width:95%;
        margin:20px auto;
    }
`;
