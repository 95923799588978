import React from 'react';
import Layout from '../common/Layout';
import Heading from '../common/ui/Heading';
import { TermsContainer } from './Component';

const Terms = ({ data, title, hideLayout }) => {

    return (
        <Layout noHeader={hideLayout} noFooter={hideLayout}>
            <TermsContainer
                className="external_pages"
            >
                <Heading
                    align="center"
                    margin="0px 0px 30px"
                    sMargin="0px 0px 20px"
                >
                    {title}
                </Heading>
                <div dangerouslySetInnerHTML={{ __html: data }} />
            </TermsContainer>
        </Layout>
    )
}

export default Terms
